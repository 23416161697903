a:focus, a:hover {
    text-decoration: none!important;
}
.header-container {
    position: fixed;
    left: 0;
    width: 100%;
    height: 126px;
    z-index: 999;
    background: #fff;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after, :where(.css-dev-only-do-not-override-26rdvq).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu:hover::after, :where(.css-dev-only-do-not-override-26rdvq).ant-menu-light.ant-menu-horizontal >.ant-menu-item-active::after, :where(.css-dev-only-do-not-override-26rdvq).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-active::after, :where(.css-dev-only-do-not-override-26rdvq).ant-menu-light.ant-menu-horizontal >.ant-menu-item-open::after, :where(.css-dev-only-do-not-override-26rdvq).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-open::after {
    border-bottom-color: #E0AF3A!important;
}

.css-1bkeob3 {
    color: rgba(0, 0, 0, 0.88);
    border-color: #d9d9d9;
    padding: 0!important;
    width: 32px;
    height: 32px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    border: none;
    background: transparent;
    border-radius: 6px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;
}

.topNav {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.specialElementStyle {
    width: 84%;
}

.menu {
    position: absolute;
    right: 0;
    top: 24px;
    border: none;
}

.mobileMenuBlock {
    display: none;
}

.mobileMenuNone {
    display: none;
}

.mobileSelectMenu {
    display: none;
}

@media screen and (min-width: 1300px) {
    .menu {
        width: 800px;
        display: block;
    }
}

@media screen and (max-width: 1280px) {
    .header-container {
        position: fixed;
        left: 0;
        width: 90%;
        height: 126px;
        z-index: 999;
        background: #fff;
        margin: 0 auto;
        display: contents;
    }

    .menu {
        display: none;
    }
    .mobileMenuBlock {
        display: block;
        border: none;
        position: absolute;
        top: 24px;
        right: 0;
        background: #fff;
        z-index: 3;
    }

    .mobileMenuNone {
        display: none;
        border: none;
        position: absolute;
        top: 24px;
        right: 0;
        background: #fff;
    }

    .mobileSelectMenu {
        width: 100vw;
        position: absolute;
        top: 94px;
        left: 0;
        z-index: 3;
        border-radius: 0;
    }
    .menuContent {
        z-index: 3;
    }

    .menuContent a {
        color: #000;
    }

    :where(.css-dev-only-do-not-override-26rdvq).ant-collapse>.ant-collapse-item >.ant-collapse-header {
        background: #fafafa;
    }

    .amobile {
        background: #fff;
        border-bottom: #fff;
    }
    .amobile .ant-collapse-header-text {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 39px;
        display: block;
        color: #000;
        border-bottom: #fff;
    }
}
