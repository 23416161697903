.managementPhoto div {
    float: left;
}

.managementPhoto div img {
    height: 393px;
}

.managementPhoto p {
    text-align: center;
}

.manageList {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.panel {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border: none;
    height: 100vh;
}

.panel {height: auto}
a:hover, a:focus { text-decoration: none; outline: none; }
#accordion { padding-right: 24px; padding-left: 24px; z-index: 1; }
#accordion .panel { border: none; box-shadow: none; }
#accordion .panel-heading { padding: 0; border-radius: 0; border: none; }
#accordion .panel-title { padding: 0; }
#accordion .panel-title a { display: block; font-size: 16px; font-weight: bold; background: #e16b47; color: #f7c59f; padding: 15px 25px; position: relative; margin-left: -24px; transition: all 0.3s ease 0s; }
#accordion .panel-title a.collapsed { background: #f7c59f; color: #e16b47; margin-left: 0; transition: all 0.3s ease 0s; }
#accordion .panel-title a:before { content: ""; border-left: 24px solid #e16b47; border-top: 24px solid transparent; border-bottom: 24px solid transparent; position: absolute; top: 0; right: -24px; transition: all 0.3s ease 0s; }
#accordion .panel-title a.collapsed:before { border-left-color: #f7c59f; }
#accordion .panel-title a:after { content: "\f106"; font-family: 'FontAwesome'; position: absolute; top: 30%; right: 15px; font-size: 18px; color: #f7c59f; }
#accordion .panel-title a.collapsed:after { content: "\f107"; color: #e16b47; }
#accordion .panel-collapse { position: relative; }
#accordion .panel-collapse.in:before { content: ""; border-right: 24px solid #f7c59f; border-bottom: 18px solid transparent; position: absolute; top: 0; left: -24px; }
#accordion .panel-body { font-size: 14px; color: #333; background: #e4e4e4; border-top: none; z-index: 1; }


@media screen and (max-width: 1280px){

    .managementPhoto div img {
        width: 80%;
        margin: 0 auto;
        display: block;
        height: auto;
    }
    .managementPhoto div{
        float: none;
    }
    .manageList {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
    .managementPhoto p img {
        width: 100%;
    }
}
