.Header {
  background: #fff!important;
  height: 126px!important;
}

.Content {
  margin-bottom: 20px;
}

.Footer {
  padding: 0!important;
}

.logo {
  width: 190px;
  padding-top: 34px;
}
