.resourceContent {
    /* min-width: 1200px; */
    width: 1200px;
    height: auto;
    background: #f5f5f5;
    display: block;
    margin: -80px auto 40px;
    z-index: 2;
    position: relative;
    color: #666;
}

.resources {
    margin: 0 auto;
    display: block;
    padding-top: 30px;
}

.childContent {
    width: 100%;
    height: 100%;
    float: left;
    background: #e4e4e4;
    margin-top: 40px;
    margin-bottom: 40px;
}

.childContent p {
    margin: 0!important;
}

.childContent img {
    margin: 0 auto;
    display: block;
}

.fontdes {
    width: 1200px;
    margin: 0 auto;
}

.fontdes h1 {
    padding-top: 5px;
    padding-left: 6px;
    border-left: 8px solid #dab215;
    padding-bottom: 5px;
}

.fontdes p {
    width: 90%;
    /*float: right;*/
    padding-bottom: 20px;
    line-height: 40px;
    /*margin-left: 20px;*/
    /*display: inline-block;*/
}

.num {
    width: 37px!important;
    height: 42px;
    background: #ff5f29;
    display: block;
    float: left!important;
    text-align: center;
    color: #f5f5f5;
    padding-bottom: 27px!important;
    font-size: 26px;
    /*margin: 7px 20px 96px 57px;*/
    margin: 7px 15px 63px 57px
}

.yellowBottom {
    position: relative;
}

.yellowBgFont {
    position: absolute;
    top: 16px;
    left: 0;
}

.yellowButtonNumber {
    width: 60%;
    margin: 0 auto;
    display: block;
    position: relative;
}

.yellowButtonNumber span {
    width: 25%;
    height: 40px;
    display: inline-block;
    text-align: center;
    background-color: #f5f5f5;
    margin-top: -42px;
}

.yellowButtonNumber span img {
    position: absolute;
    top: -80px;
    margin-left: 60px;
    display: flex;
}

.topbg {
    width: 80%;
    height: 80px;
    /* margin-bottom: 90px; */
    margin: 0 auto 190px;
}

.topbggrey {
    width: 31.94%;
    height: 160px;
    background-color: #e6e6e6;
    display: block;
    float: left;
    border-radius: 10px;
    line-height: 40px;
}

.topbggreynumber {
    display: none;
}

.bottomThreePic {
    width: 1200px;
    margin: 0 auto;
}

.bottomThreePic img{
    width: 31.3%;
    height: 239px;
}

.bottomMiddlePic {
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.childContent p img {
    max-width: 1200px;
}

@media screen and (max-width: 1280px){
    .bottomThreePic {
        width: 80%;
        margin: 0 auto;
    }

    .bottomMiddlePic {
        margin: 30px 0;
    }

    .bottomThreePic img{
        width: 100%;
        height: auto;
    }
    .fontdes {
        width: 80% !important;
        margin: 0 auto;
    }
    .num {
        display: none!important;
    }
    .childContent p img {
        width: 100%;
    }
    .fontDesc {
        text-align: center;
    }
    .topbg {
        height: auto!important;
        margin: 0 auto 20px!important;
    }
    .resourceContent {
        width: 100%!important;
        margin: 0!important;
    }

    .SecondGreyBox {
        margin-left: 0!important;
        margin-right: 0!important;
    }

    .topbggrey {
        width: 80%!important;
        margin: 20px auto!important;
        float: none;
    }

    .yellowBottom {
        display: none!important;
    }

    .yellowButtonNumber img {
        display: none!important;
    }

    .topbggreynumber {
        display: block!important;
        text-align: center;
        display: block;
        background: #E0AF3A;
        width: 80%;
        margin: 0 auto;
        padding: 6px;
        border-radius: 5px;
        color: #fff;
    }
}
