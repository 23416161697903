.xuankuangchang_bottom_pic {
    width: 80%;
    margin: 0 auto;
    position: relative;
}



.bmpic {
    width: 47%;
    float: left;
}

.bmpic p {
    text-align: center;
}

.bmpic img {
    width: 100%;
    height: 400px;
}

.plantsContent {
    max-width: 1200px;
    margin: 0 auto;
}

.plantsTopList {
    width: 90%;
    margin: 0 auto!important;
    margin-bottom: 40px;
}

.plantsList {
    width: 33.3%;
    float: left;
    list-style: none;
}

.listtitle {
    width: 94%;
    padding: 10px;
    background: #dab215;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
}

.list div {
    width: 80%;
    padding-top: 20px;
}

.plantsBottomList {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 40px;
}

.blist {
    width: 25%;
    float: left;
    list-style: none;
}

.blist div {
    width: 90%;
}

.bottomDesc {
    width: 60%;
    margin: 0 auto 40px;
}

.plantContent ul {
    padding-left: 0!important;
}

.bottomcontent p span {
    background-color: #f5f5f5!important;
}

.plantContent {
    width: 87%;
    margin-left: 20px;
}

.plantContent1 {
    /*height: 458px;*/
    /*height: 1012px;*/
    position: relative;
}

/*.plantContent1 p img {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*}*/

.plantContent2 {
    /*height: 458px;*/
    /*height: 1012px;*/
    position: relative;
}

.xuankuang {
    width: 1200px;
}
/*.plantContent2 p img {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*}*/

.middlePic img {
    width: 48%;
    float: left;
    margin-top: 40px;
    margin-bottom: 40px;
}

.bmpic2 {
    float: right;
}

@media screen and (max-width: 1280px){
    .bmpic2 {
        float: none;
    }
    .bmpic img {
        width: 90%;
        height: auto;
    }
    .xuankuang {
        width: 90%;
    }
    .bmpic {
        width: 100%;
    }

    .bmpic p {
        text-align: center;
    }
    .plantsList {
        width: 90%;
        float: none;
        margin: 0 auto;
    }
    .Processing img {
        width: 100%;
    }
    .bottomDesc {
        width: 90%;
    }
    .plantsBottomList {
        width: 90%;
    }
    .blist {
        width: 100%;
        float: none;
        margin: 30px auto;
    }
    .middlePic img {
        width: 100%;
        float: none;
        margin: 0 auto 0 0!important;
    }

    .xuankuangchang_bottom_pic img {
        width: 100%;
    }
}
