.ReportsContent {
    max-width: 1200px;
    margin: 30px auto;
}

.article_title {
    color: #000;
}

.article_title:hover {
    color: #E0AF3A;
    text-decoration: underline;
}
