.bottomGoldPic {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.bottomGoldPic img {
    width: 49%;
}

.rightGoldPic {
    position: absolute;
    right: 0;
}

.goldLocationContent ul {
    width: 34%;
    margin-top: -500px;
    float: right;
    line-height: 30px;
}

.goldLocationContent ul li span {
    background-color: #f5f5f5!important;
}

.goldLocationContent {
    max-width: 1200px;
    margin: 30px auto 0;
}

.goldLocationContent h1 span {
    font-size: 40px;
}

.contentTitle {
    font-size: 30px;
    color: #ff5f29;
    padding-top: 60px;
    padding-left: 60px;
    padding-bottom: 40px;
}

.topPic {
    margin: 0 auto;
    display: block;
    padding-bottom: 40px;
}

.bottomPic {
    margin: 0 auto;
    display: block;
    padding-bottom: 40px;
}

.goldLocationContent p img {
    max-width: 1200px;
    display: block;
}


@media screen and (max-width: 1280px) {
    .goldLocationContent ul {
        width: 80%;
        line-height: 30px;
        display: block;
        float: none;
        margin: 0;
    }

    .goldLocationContent h1 span {
        font-size: 22px;
        text-align: center;
        display: block;
    }

    .goldLocationContent p img {
        width: 80%;
        display: block;
        margin: 0 auto;
    }

    .bottomGoldPic img {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 30px;
        display: block;
    }

    .rightGoldPic {
        position: relative;
        margin-top: 20px;
    }
}
