.EnvContent {
    width: 80%;
    background: #ffffff;
    max-width: 1200px;
    padding: 80px;
    margin: -80px auto 0;
    position: relative;
    z-index: 2;
}


.EnvContent img {
    width: 50%;
    height: 400px;
    object-fit: cover;
    padding: 20px;
}

.middlePic {
    width: 100%;
    display: flow-root;
}

.middlePic img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    padding: 20px;
}

@media screen and (max-width: 1366px) {
    .renwu img {
        width: 100%!important;
        height: auto!important;
    }
    .EnvContentBody img {
        width: 100%;
    }

}

/*.EnvContent p {*/
/*    font-size: 0.9vw;*/
/*}*/

