.content {
    width: 70%;
    height: auto;
    display: block;
}

.linksContainer {
    text-align: center;
    line-height: 30px;
    font-size: 22px;
    width: fit-content;
    margin: 20px auto;
}

.linksContainer p {
    color: #000;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    padding-top: 20px;
}

.linksContainer a {
    color: #000000;
}

.linksContainer a:hover {
    text-decoration: underline;
}
