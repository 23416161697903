.navBottomBanner {
    position: relative;
}

.navBottomBanner img {
    width: 100%;
}

.navBottomBannerFonts {
    color: #fff;
    position: absolute;
    -moz-top: 50%;
    -moz-left: 40%;
    -moz-margin: -50px 0 0 -50px;
    -webkit-top: 0;
    -webkit-right: 0;
    -webkit-bottom: 0;
    -webkit-left: 0;
    -webkit-margin: auto;
    -webkit-display: table;
}

/*.navBottomBannerFonts {*/
/*    color: #fff;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    margin: auto;*/
/*    display: table;*/
/*}*/

.firstLine {
    font-size: 3.5vw;
}

.secondLine {
    font-size: 3vw;
}

@media screen and (max-width: 1280px) {
    .navBottomBannerFonts {
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: table;
    }
    .firstLine {
        font-size: 12px;
    }

    .secondLine {
        font-size: 16px;
    }
}
