.footer-container {
    width: 100%;
    height: 80px;
    /*position: relative;*/
    /*bottom: 0;*/
    /*left: 0;*/
    background: #000;
}

.copyright {
    text-align: center;
    padding-top: 30px;
    color: #fff;
    font-size: 16px;
    display: block;
}

@media screen and (max-width: 1280px) {
    .copyright {
        text-align: center;
        padding-top: 30px;
        color: #fff;
        font-size: 14px;
        display: block;
    }
}
