-moz-body {
    height: 100%;
}
.navBottomBanner {
    position: relative;
}

.navBottomBanner img {
    width: 100%;
}

.dContentTitle {
    background: #ff5f29;
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px!important;
    padding: 4px;
    text-shadow: 1px 1px 1px #000;
    width: 100%;
}

.EnvContentBody {
    padding: 100px!important;
}

/*.navBottomBannerFonts {*/
/*    font-size: 82px;*/
/*    color: #fff;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    margin: auto;*/
/*    display: table;*/
/*}*/

.navBottomBannerFonts * {
    margin: auto;
}

.navBottomBannerFonts {
    position: absolute; /* h1设置为绝对定位 */
    top: 50%; /* 垂直居中 */
    left: 40%; /* 水平居中 */
    transform: translate(-40%, -50%); /* 通过transform属性微调居中位置 */
    z-index: 1; /* 将h1标签的层级设置为1，使其悬浮在同级img标签之上 */
    font-size: 2.7vw; /* 自适应字体大小 */
}

/*.navBottomBannerFonts {*/
/*    color: #fff;*/
/*    position: absolute;*/
/*    top: 25%;*/
/*    font-size: 70px;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    margin: auto;*/
/*    display: table;*/
/*    width: 30%;*/
/*}*/

@media screen and (max-width: 1280px) {
    .navBottomBannerFonts {
        position: absolute; /* h1设置为绝对定位 */
        top: 50%; /* 垂直居中 */
        left: 40%; /* 水平居中 */
        transform: translate(0%, 0%); /* 通过transform属性微调居中位置 */
        z-index: 1; /* 将h1标签的层级设置为1，使其悬浮在同级img标签之上 */
        font-size: 16px;
    }

    .reportsBanner {
        height: auto!important;
        overflow-x: hidden!important;
    }

    .companyBanner {
        height: auto!important;
        overflow-x: hidden!important;
    }

    .contactusBanner {
        height: auto!important;
        overflow-x: hidden!important;
    }

    .LinksBanner {
        height: auto!important;
        overflow-x: hidden!important;
    }

}

@media screen and (max-width: 768px){
    .subtitle {
        width: 100%;
        height: 150px;
        padding: 8px;
    }

    .subtitle ul li {
        width: 50%;
        /*border: 1px solid #000000;*/
        float: left!important;
        padding: 0;
        height: 75px;
    }

    .subtitle ul li p {
        margin: 0;
    }

    .subtitle ul li h1 {
        margin: 0;
    }

    .middleMessage {
        margin-top: 0;
    }

    .topInvestorTitle {
        padding: 0;
    }
    .EnvContent img {
        width: 100% !important;
        padding: 20px;
        margin: 0 auto;
        display: block;
    }
    .EnvContent p {
        font-size: 16px;
    }

    .EnvContent {
        width: 100%!important;
        background: #ffffff;
        max-width: 1200px;
        padding: 10px!important;
        margin: 0 auto!important;
        position: relative;
        z-index: 1999;
    }
}
