.developmentBottomPic {
    margin: 0 auto;
    display: table;
}

.developmentBottomPic div {
    float: left;
}

.developmentBottomPic div p {
    text-align: center;
}



.caikuangzheng {
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
}

.developContent {
    /* min-width: 1200px; */
    width: 1200px;
    height: auto;
    background: #fff;
    display: block;
    margin: -80px auto 40px;
    z-index: 2;
    position: relative;
    color: #666;
}

.development {
    width: 80%;
    margin: 0 auto;
    display: block;
    padding-top: 30px;
}

.developChildContent {
    width: 100%;
    height: 100%;
    float: left;
    background: #e4e4e4;
    margin-top: 40px;
    margin-bottom: 40px;
}

.realWidth {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.developChildContent img {
    float: right;
}


.devfontdes {
    float: left;
    width: 580px;
    margin-top: 10px;
}

.devfontdes li {
    line-height: 38px;
    color: #222;
}

.devfontdes li::marker {
    color: #E0AF3A;
}

.devBottomPic img {
    object-fit: cover;
    height: 459px;
}

@media screen and (max-width: 1280px){
    .developmentBottomPic div {
        float: none;
    }
    .developmentBottomPic div img {
        width: 100%;
        height: 400px;
    }
    .developContent {
        width: 100%;
        margin: 0;
    }

    .realWidth {
        width: 100%;
    }

    .devfontdes {
        width: 90%;
    }

    .developChildContent img {
        width: 100%;
        float: none;
    }
}
