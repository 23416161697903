.bottomOperPic {
    max-width: 1200px;
    margin: 0 auto;
    /*position: relative;*/
}


.bottomOperPic img {
    width: 50%;
    padding: 20px;
    max-height: 760px;
}

.rightOperPic {
    position: absolute;
    right: 0;
}

.operationsContent {
    /* min-width: 1200px; */
    width: 1200px;
    height: auto;
    background: #f5f5f5;
    display: block;
    margin: -80px auto 40px;
    z-index: 2;
    position: relative;
    color: #666;
}

.content-title {
    margin: 0 auto;
    display: block;
    background: #dbb316;
    padding: 6px 6px 6px 16px;
    color: #fff;
}

.sm-title {
    padding-top: 30px;
    padding-bottom: 30px;
}

.topList {
    margin-bottom: 40px;
}

.topList li {
    width: 33.3%;
    float: left;
    list-style: none;
}

.topList li p {
    width: 80%;
    padding: 4px;
    background: #ff5f29;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    margin-bottom: 0!important;
    font-size: 16px;
}

.topList li div {
    width: 80%;
    padding-top: 4px;
}

.bottomList {
    margin-bottom: 30px;
}

.bottomListLi {
    width: 33.3%;
    float: left;
    list-style: none;
}

.bottomList li p {
    width: 80%;
    padding: 4px;
    background: #dab215;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    margin-bottom: 0!important;
}

.bottomListLi ul {
    width: 80.4%;
    /*background: linear-gradient(to bottom, #eee, #fff);*/
    padding: 20px;
    box-sizing: border-box;
}

.content5 ul {
    margin: 0 auto;
}

.content6 ul {
    float: right;
}

.contentSpan span {
    background-color: #f5f5f5!important;
}

@media screen and (max-width: 1280px){
    .operationsContent {
        width: 100%!important;
        margin: 0!important;
    }
    .opcontent {
        width: 90%!important;
        min-width: 90%!important;
        margin: 0 auto!important;
    }

    .topList li {
        width: 100%!important;
        float: none!important;
        margin: 50px auto!important;
    }
    .title1 {
        float: none!important;
        margin: 0 auto!important;
    }
    .content1 {
        float: none!important;
        margin: 0 auto!important;
    }
    .title3 {
        float: none!important;
        margin: 0 auto!important;
    }
    .content3 {
        float: none!important;
        margin: 0 auto!important;
    }
    .title4 {
        float: none!important;
        margin: 0 auto!important;
    }
    .content4 {
        float: none!important;
        margin: 0 auto!important;
    }
    .content4 ul {
        float: none!important;
        margin: 0 auto!important;
    }
    .title6 {
        float: none!important;
        margin: 0 auto!important;
    }
    .content6 {
        float: none!important;
        margin: 0 auto!important;
    }
    .content6 ul {
        float: none!important;
        margin: 0 auto!important;
    }
    .bottomListLi {
        width: 100%;
        margin-right: 0;
        margin-bottom: 50px;
    }

    .bottomOperPic img {
        width: 100%;
        height: auto;
    }

    .rightOperPic {
        position: relative;
        margin-top: 20px;
    }
}
