.visionContent {
    width: 1200px;
    height: auto;
    background: #fff;
    display: table;
    box-shadow: 0 0 10px 2px #999999;
    margin: -80px auto 40px;
    z-index: 2;
    position: relative;
    color: #666;
}

.vision-container {
    padding: 30px;
}

.labelClass {
    width: 380px;
    text-align: center;
    float: left;
}

.titleContent, .titleFont {
    width: 80%;
    text-align: left;
    margin-bottom: 10px;
}

.titleFont {
    font-size: 24px;
    font-weight: bold;
}

.labelTitle {
    width: 80%;
    margin: 0 auto;
    text-align: left;
}

@media screen and (max-width: 1280px){
    .visionContent {
        width: 100%;
        height: auto;
        background: #fff;
        display: table;
        box-shadow: 0 0 10px 2px #999999;
        margin: 0;
        z-index: 2;
        position: relative;
        color: #666;
    }

    .label {
        width: 100%;
    }

    .labelTitle {
        width: 100%;
    }
}
