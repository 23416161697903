html {
    --antd-wave-shadow-color: #E0AF3A!important;
    --scroll-bar: 0;
}

:root {
    --swiper-theme-color: #E0AF3A!important;
}

.ant-menu-light .ant-menu-submenu-title:hover {
    color: #E0AF3A!important;
}

.ant-menu-item a:hover {
    color: #E0AF3A!important;
}

a:hover {
    color: #E0AF3A!important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    color: #E0AF3A!important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
    border-bottom: 2px solid #E0AF3A!important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #E0AF3A!important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid #E0AF3A!important;
}

.ant-pagination-item-active {
    border-color: #E0AF3A!important;
}

.ant-pagination-item-active a {
    color: #E0AF3A!important;
}

.ant-pagination-item:hover {
    border-color: #E0AF3A!important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #E0AF3A!important;
}

::selection {
    background: #E0AF3A!important;
}

.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: #E0AF3A!important;
    border: 1px solid #E0AF3A!important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #E0AF3A!important;
}
