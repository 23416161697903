.ContactUsContent {
    max-width: 1200px;
    margin: 20px auto;
}

.submitForm input{
    margin-bottom: 20px;
}

.mail {
    color: #000;
}

.mail:hover {
    color: #000;
    text-decoration: underline;
}

