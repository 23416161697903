

.banner {
    width: 100%;
}

.bannerGruop {
    position: relative;
}

/*.wordGroup {*/
/*    width: 1200px;*/
/*    margin: -550px auto 0;*/
/*    padding-bottom: 400px;*/
/*}*/

.wordGroup {
    max-width: 1200px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: table;
}

.banner img {
    width: 100%;
    cursor: pointer;
    z-index: 1;
}

.subtitle {
    max-width: 1170px;
    height: 164px;
    border: 1px solid #f5f5f5;
    z-index: 1;
    margin: -164px auto 0;
    background: #f5f5f5;
    display: flex;
    position: relative;
    bottom: -4px;
}

.subtitle ul li {
    width: 25%;
    float: left;
    padding: 15px;
    box-sizing: border-box;
    list-style: none;
    border-right: 1px solid #000;
    height: 80%;
    color: #595758;
    position: relative;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.subtitle ul li svg {
    position: absolute;
    right: 30px;
    bottom: 10px;
    cursor: pointer;
}

.subtitle ul li p {
    font-weight: bold;
}

.roll {
    width: auto;
    height: 100%;
    background: #f4f4f4;
    padding-left: 400px!important;
}

.content {
    width: 800px!important;
    float: left;
    cursor: pointer;
    margin-right: 20px;
    height: auto!important;
}

.scrollContentTitle {
    padding-left: 20px;
}

.swiper-scrollbar {
    display: none!important;
}

.swiper {
    padding-left: 370px!important;
}

.scrollContentContent p img {
    width: 40%!important;
    height: 100%!important;
    position: absolute;
    left: 0;
    top: 0;
}

.swiper-slide-active {
    background-color: #E0AF3A!important;
}


.scrollContentContent {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 20px;
    padding-right: 20px;
}

.titleContent {
    width: 100%;
    line-height: 30px;
    padding-top: 10px;
}

.scrollContentTitle {
    font-weight: bold;
    font-size: 20px;
}

.aboutGroup ul li {
    list-style: none;
}

.content img {

    display: inline-block;
}

.frist {
    background: #E0AF3A;
}
/*.swiper-slide-active {*/
/*    background: #E0AF3A;*/
/*}*/

.aboutGroup {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px;
}

.aboutGroup ul {
    width: 33.3%;
    float: left;
}

.aboutGroup p{
    display: none;
}

.smTitle {
    font-weight: bold;
    font-size: 20px;
}

.middleMessage {
    margin-top: 80px;
    background: #f4f4f4;
}

.reportsMessage {
    background: #f4f4f4;
}

.middleMessage img {
    width: 100%;
}

.middleFontDesc {
    position: relative;
    max-width: 1200px;
    margin: -100px auto 0;
    /*margin: 0 auto;*/
    border: 1px solid #fff;
    background: #fff;
    z-index: 2;
}

.middleMessageTitle {
    width: 80%;
    text-align: center;
    font-size: 36px;
    color: #ff5f29;
    font-weight: bold;
    margin: 30px auto;
    z-index: 1;
}

.middleMessageSmallTitle {
    width: 72%;
    font-size: 20px;
    margin:  20px auto;
    padding: 4px 4px 4px 30px;
    color: #fff;
    background: #E0AF3A;
}

.middleMessageArtcle {
    width: 74%;
    margin: 0 auto;
    display: block;
}

.middleMessageArtcle p {
    margin-bottom: 30px;
}

.indexNews {
    width: 1200px;
    margin: 60px auto;
}

.indexNewsTitle {
    border-left: 8px solid #E0AF3A;
    padding-left: 10px;
    box-sizing: border-box;
}

.indexNewsTitleViewAllNews  {
    width: 120px;
    position: relative;
    display: block;
    color: #000;
    text-decoration: none;
}

.indexNewsTitleViewAllNews:hover {
    text-decoration: underline;
}

.indexNewsTitleViewAllNews svg {
    position: absolute;
    top: 0;
    right: 0;
}

.indexNewsList {
    width: 40%;
    float: left;
}

.newsList {
    padding-left: 15px;
    padding-top: 30px;
}


.indexNewsListNewsPushDate {
    color: #a4a4a4;
    padding-bottom: 10px;
}

.indexNewsListNewsTitle {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    cursor: pointer;
}

.star {
    width: 28px;
    display: flex;
    position: absolute;
    margin-left: -32px;
    margin-top: 4px;
}

.indexNewsPDFModuleTitle {
    width: 50%;
    color: #ff5f29;
    font-size: 30px;
    font-weight: bold;
}

.indexNewsPDF {
    width: 50%;
    float: right;
}

.indexNewsPDFModule {
    margin-bottom: 90px;
}

.indexNewsPDFModuleDesc {
    width: 50%;
    float: left;
}

.indexNewsPDFImage {
    float: right;
    margin-top: -30px;
}

@media screen and (max-width: 1280px) {
    .wordGroup p span {
        font-size: 12px!important;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        display: block;
    }
    .subtitle {
        width: 80%;
        position: unset;
        margin: 0 auto;
        display: block;
        border: none;
        height: auto;
        background-color: transparent;
    }

    .subtitle ul li {
        width: 80%;
        float: none!important;
        border: none;
    }

    .subtitle ul li svg {
        display: none!important;
    }

    .aboutGroup {
        width: 100%;
    }

    .aboutGroup ul {
        width: 80%;
        margin: 0 auto;
        float: none;
    }

    .aboutGroup ul li img {
        margin: 0 auto;
    }

    .swiper {
        display: none;
    }

    .indexNews {
        width: 90%!important;
    }

    .indexNewsList {
        width: 80%!important;
        margin: 0 auto!important;
        float: none!important;
    }
    .indexNewsPDF {
        width: 80%!important;
        margin: 0 auto!important;
        float: none!important;
    }

    .middleFontDesc {
        margin: 0 auto!important;
    }
}
