.chairmanContent {
    width: 70%;
    height: auto;
    background: #fff;
    display: table;
    box-shadow: 0 0 10px 2px #999999;
    margin: -20px auto 40px;
    z-index: 2;
    position: relative;
    color: #666;
}

.chairmanContentTitle {
    font-size: 40px;
    color: #ff5f29;
    padding-top: 60px;
    /*padding-left: 60px;*/
    padding-left: 130px;
}

.photo {
    float: left;
    padding-top: 50px;
    padding-left: 60px;
}

.photo p {
    padding-top: 20px;
    text-align: center;
}

.chairmanFont {
    width: 80%;
    /*width: 60%;*/
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    /*padding-right: 60px;*/
    font-size: 16px;
    line-height: 40px;
    /*float: right;*/
}
.font p {
    padding-bottom: 40px;
}


@media screen and (max-width: 1280px) {
    .photo img {
        width: 80%;
        margin: 0 auto;
        display: block;
    }
    .photo {
        padding: 0;
        float: none;
    }
    .photo p {
        width: 100%;
        text-align: center;
    }
    .chairmanContent {
        margin: 0 auto;
        width: 100%;
    }
    .chairmanFont {
        width: 90%;
    }
}
